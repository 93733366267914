import type { EquipamentosFormSectionFormValues } from '@/components/core/form-section/equipamentos/equipamentos-form-section'
import type { ChavesPixFormValues } from '@/components/core/FormSections/ChavesPix/ChavesPix'
import type { ConcessionariasFormSectionFormValue } from '@/components/core/FormSections/Concessionarias/ConcessionariasFormSectionEditable'
import type { ContasBancariasFormValues } from '@/components/core/FormSections/ContasBancarias/ContasBancarias'
import type { DadosContatoEmpresaFormValues } from '@/components/core/FormSections/DadosContatoEmpresa/DadosContatoEmpresa'
import type { DadosEmpresaFormValues } from '@/components/core/FormSections/DadosEmpresa/DadosEmpresa'
import type { DadosEnderecoFormValues } from '@/components/core/FormSections/DadosEndereco/DadosEndereco'
import type { ResidenciaCamposFormValues } from '@/components/core/FormSections/DadosEndereco/ResidenciaCampos/ResidenciaCampos'
import type { DistribuidoresFormSectionFormValues } from '@/components/core/FormSections/Distribuidores/DistribuidoresFormSection'
import type { RedesSociaisFormValues } from '@/components/core/FormSections/RedesSociais/RedesSociais'
import type { ResponsavelTecnicoFormValues } from '@/components/core/FormSections/ResponsavelTecnico/ResponsavelTecnico'
import type { FileRecord, Option, Translate } from '@/utils/types/common'
import type { Empresa } from '@/utils/types/structs/auth'

import type { Equipamento } from './structs'
import type { Milestone } from './structs/proposta'

export * from './server'

export const tiposArquivo = [
  'doc_pessoal',
  'conta_energia',
  'arquivo',
  'doc_social',
] as const

export type TipoArquivo = (typeof tiposArquivo)[number]

export const tipoPessoa = ['PESSOA_FISICA', 'PESSOA_JURIDICA'] as const
export type TipoPessoa = (typeof tipoPessoa)[number]

export const tipoContaBanco = [
  'CORRENTE',
  'POUPANCA',
  'CONJUNTA',
  'CHECKING',
  'SAVINGS',
] as const
export type TipoContaBanco = (typeof tipoContaBanco)[number]

export const tipoContaBancoOptions: Option<TipoContaBanco>[] = [
  { label: 'Conta corrente', value: 'CORRENTE' },
  { label: 'Conta poupança', value: 'POUPANCA' },
  { label: 'Conta conjunta', value: 'CONJUNTA' },
]

export const tipoPessoaOptions: Option<TipoPessoa>[] = [
  { label: 'Pessoa Física PF', value: 'PESSOA_FISICA' },
  { label: 'Pessoa Jurídica PJ', value: 'PESSOA_JURIDICA' },
]

export const tiposChavePix = [
  'CPF',
  'CNPJ',
  'CELULAR',
  'ALEATORIA',
  'EMAIL',
] as const

export type TipoChavePix = (typeof tiposChavePix)[number]

export const tipoChavePixOptions: Option<TipoChavePix>[] = [
  { label: 'CNPJ', value: 'CNPJ' },
  { label: 'Celular', value: 'CELULAR' },
  { label: 'Chave aleatória', value: 'ALEATORIA' },
  { label: 'Chave email', value: 'EMAIL' },
]

export const statusUsuario = [
  'APROVADO',
  'REPROVADO',
  'EM_ANALISE',
  'BLOCKLIST',
  'REDLIST',
  'LEAD',
  'SEM_INTERESSE',
] as const

export type StatusUsuario = (typeof statusUsuario)[number]

export const statusIntegradorOptions = (
  user?: any,
  context?: any,
  t?: Translate
): Option<StatusUsuario>[] => {
  const isOperador = !user?.isAdmin

  return [
    { label: t ? t('status.under_review') : 'Em análise', value: 'EM_ANALISE' },
    { label: t ? t('status.approved_partner') : 'Aprovado', value: 'APROVADO' },
    { label: t ? t('status.refused') : 'Reprovado', value: 'REPROVADO' },
    { label: t ? t('status.lead') : 'Lead', value: 'LEAD' },
    {
      label: t ? t('status.no-interest') : 'Sem Interesse',
      value: 'SEM_INTERESSE',
    },
    ...(context === 'BACKOFFICE' && !isOperador
      ? ([{ label: 'Blocklist', value: 'BLOCKLIST' }] as never)
      : []),
    ...(context === 'BACKOFFICE' && !isOperador
      ? ([{ label: 'Redlist', value: 'REDLIST' }] as never)
      : []),
  ]
}

export const statusUsuarioColor = (
  status: StatusUsuario,
  user?: any,
  context?: any
) => {
  const isOperador = !user?.isAdmin

  switch (status) {
    case 'APROVADO':
      return 'text-success-500 border-success-300 bg-success-100'

    case 'EM_ANALISE':
    case 'LEAD':
      return 'text-warning-500 border-warning-200 bg-warning-100'

    case 'REPROVADO':
    case 'SEM_INTERESSE':
      return 'text-danger-500 border-danger-200 bg-danger-100'

    case 'BLOCKLIST':
      if (context === 'BACKOFFICE' && isOperador) {
        return 'text-danger-500 border-danger-200 bg-danger-100'
      }
      if (context !== 'BACKOFFICE') {
        return 'text-danger-500 border-danger-200 bg-danger-100'
      }
      return 'text-light-gray-100 border-dark-gray-300 bg-dark-gray-100'

    case 'REDLIST':
      if (context === 'BACKOFFICE' && isOperador) {
        return 'text-danger-500 border-danger-200 bg-danger-100'
      }
      if (context !== 'BACKOFFICE') {
        return 'text-danger-500 border-danger-200 bg-danger-100'
      }
      return 'text-white border-danger-300 bg-danger-300'

    default:
      return 'text-light-gray-500 border-light-gray-500'
  }
}

export const statusUsuarioDisplay = (
  user?: any,
  context?: any,
  t?: Translate
): Record<StatusUsuario, string> => {
  const isOperador = !user?.isAdmin

  const display = {
    ...statusIntegradorOptions(user, context, t).reduce<
      Record<StatusUsuario, string>
    >((a, b) => ({ ...a, [b.value]: b.label }), {} as never),
  }

  if (context === 'BACKOFFICE' && isOperador) display.BLOCKLIST = 'Reprovado'
  if (context !== 'BACKOFFICE') display.BLOCKLIST = 'Reprovado'

  return display
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]> | null
    }
  : T

export const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'DF',
] as const

export interface MongoDBDocument {
  _id: { toString(): string }
  __v: number
  createdAt: string
  updatedAt: string
}

export const statesOptions: {
  label: string
  value: (typeof states)[number]
}[] = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Espirito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
  { label: 'Distrito Federal', value: 'DF' },
]

export const tipoSexOption = ['MASCULINO', 'FEMININO', 'NAO_INFORMADO'] as const

export type TipoSexOption = (typeof tipoSexOption)[number]

export const sexOptions = (t: Translate): Option<TipoSexOption>[] => {
  return [
    { label: t('proposal.opt_marital_male'), value: 'MASCULINO' },
    { label: t('proposal.opt_marital_female'), value: 'FEMININO' },
    { label: t('proposal.opt_marital_none'), value: 'NAO_INFORMADO' },
  ]
}

export const tipoEstadoCivil = [
  'SOLTEIRO',
  'CASADO',
  'SEPARADO',
  'DIVORCIADO',
  'VIUVO',
] as const

export type TipoEstadoCivil = (typeof tipoEstadoCivil)[number]

export const estadoCivilOptions = (t: Translate): Option<TipoEstadoCivil>[] => {
  return [
    { label: t('proposal.opt_single'), value: 'SOLTEIRO' },
    { label: t('proposal.opt_married'), value: 'CASADO' },
    { label: t('proposal.opt_separated'), value: 'SEPARADO' },
    { label: t('proposal.opt_divorced'), value: 'DIVORCIADO' },
    { label: t('proposal.opt_widowed'), value: 'VIUVO' },
  ]
}

// Situação do imovel* (Quitado, financiado e familiar)

export const tipoSituacaoImovel = ['QUITADO', 'FINANCIADO', 'FAMILIAR'] as const
export const tipoProjeto = ['SOLAR', 'HOMEIMPROVE'] as const
export const tipoTelefone = ['CELULAR', 'FIXO', 'TRABALHO'] as const
export const situacaoResidencial = [
  'USCITIZEN',
  'PERMRESIDENT',
  'NONPERMRESIDENT',
] as const
export const situacaoProfissional = [
  'FULLTIME',
  'PARTTIME',
  'AUTONOMO',
] as const

export type TipoSituacaoImovel = (typeof tipoSituacaoImovel)[number]
export type TipoProjeto = (typeof tipoProjeto)[number]
export type TipoTelefone = (typeof tipoTelefone)[number]
export type SituacaoResidencial = (typeof situacaoResidencial)[number]
export type SituacaoProfissional = (typeof situacaoProfissional)[number]

export const situacaoImovelOptions = (
  t: Translate
): Option<TipoSituacaoImovel>[] => {
  return [
    { label: t('proposal.opt_paid_off'), value: 'QUITADO' },
    { label: t('proposal.opt_financed'), value: 'FINANCIADO' },
    { label: t('proposal.opt_family_oriented'), value: 'FAMILIAR' },
  ]
}
export const TipoProjetoOptions = (t: Translate): Option<TipoProjeto>[] => {
  return [
    { label: t('proposal.solar-pv-only'), value: 'SOLAR' },
    { label: 'Home Improvement', value: 'HOMEIMPROVE' },
  ]
}
export const TipoTelefoneOptions = (t: Translate): Option<TipoTelefone>[] => {
  return [
    { label: t('proposal.phone-mobile'), value: 'CELULAR' },
    { label: t('proposal.phone-home'), value: 'FIXO' },
    { label: t('proposal.phone-work'), value: 'TRABALHO' },
  ]
}
export const SituacaoResidencialOptions = (): Option<SituacaoResidencial>[] => {
  return [
    { label: 'US Citizen', value: 'USCITIZEN' },
    { label: 'Permanent Resident', value: 'PERMRESIDENT' },
    { label: 'Non Permanent Resident', value: 'NONPERMRESIDENT' },
  ]
}
export const SituacaoProfissionalOptions = (
  t: Translate
): Option<SituacaoProfissional>[] => {
  return [
    {
      label: t('proposal.professional-situation-self-employed'),
      value: 'AUTONOMO',
    },
    {
      label: t('proposal.professional-situation-full-time'),
      value: 'FULLTIME',
    },
    {
      label: t('proposal.professional-situation-part-time'),
      value: 'PARTTIME',
    },
  ]
}

// Natureza da ocupação* (Aposentado/Pensionista, assalariado, empresário, autonomo, funcionário público e profissional liberal)

export const tipoNaturezaOcupacao = [
  'APOSENTADO_PENSIONISTA',
  'ASSALARIADO',
  'EMPRESARIO',
  'AUTONOMO',
  'FUNCIONARIO_PUBLICO',
  'PROFISSIONAL_LIBERAL',
] as const

export type TipoNaturezaOcupacao = (typeof tipoNaturezaOcupacao)[number]

export const naturezaOcupacaoOptions = (
  t: Translate
): Option<TipoNaturezaOcupacao>[] => {
  return [
    { label: t('proposal.opt_retired'), value: 'APOSENTADO_PENSIONISTA' },
    { label: t('proposal.opt_salaried_employee'), value: 'ASSALARIADO' },
    {
      label: t('proposal.opt_entrepreneur_usiness_owner'),
      value: 'EMPRESARIO',
    },
    { label: t('proposal.opt_selfemployed_freelancer'), value: 'AUTONOMO' },
    { label: t('proposal.opt_public_employee'), value: 'FUNCIONARIO_PUBLICO' },
    {
      label: t('proposal.opt_selfemployed_professional'),
      value: 'PROFISSIONAL_LIBERAL',
    },
  ]
}

export const tipoFunding = [
  'BANCO_COMERCIAL',
  'FINANCEIRA',
  'SECURITIZADORA',
  'FIDC',
  'FINTECH',
] as const

export type TipoFunding = (typeof tipoFunding)[number]

export const tipoFundingOptions: Option<TipoFunding>[] = [
  { label: 'Banco comercial', value: 'BANCO_COMERCIAL' },
  { label: 'Financeira', value: 'FINANCEIRA' },
  { label: 'Securitizadora', value: 'SECURITIZADORA' },
  { label: 'FIDC', value: 'FIDC' },
  { label: 'Fintech', value: 'FINTECH' },
]
export const Carteira = [
  { label: 'Banco 01', value: 'Banco 01' },
  { label: 'Banco 02', value: 'Banco 02' },
  { label: 'Banco 03', value: 'Banco 03' },
  { label: 'Banco 04', value: 'Banco 04' },
  { label: 'Banco 05', value: 'Banco 05' },
]

export const tipoFundingDisplay: Record<TipoFunding, string> = {
  BANCO_COMERCIAL: 'Banco comercial',
  FINANCEIRA: 'Financeira',
  SECURITIZADORA: 'Securitizadora',
  FIDC: 'FIDC',
  FINTECH: 'Fintech',
}

export const tipoFundingParse = (tipo: TipoFunding) => {
  const tipoParsed = {
    BANCO_COMERCIAL: 'Banco comercial',
    FINANCEIRA: 'Financeira',
    SECURITIZADORA: 'Securitizadora',
    FIDC: 'FIDC',
    FINTECH: 'Fintech',
  }[tipo]

  return tipoParsed
}

export const tipoClienteFunding = [
  'AMBAS',
  'PESSOA_FISICA',
  'PESSOA_JURIDICA',
] as const

export type TipoClienteFunding = (typeof tipoClienteFunding)[number]

export const tipoClienteFundingOptions: Option<TipoClienteFunding>[] = [
  { label: 'Ambas', value: 'AMBAS' },
  { label: 'Pessoa Física', value: 'PESSOA_FISICA' },
  { label: 'Pessoa Jurídica', value: 'PESSOA_JURIDICA' },
]

export interface BrasilAPICepResponse {
  cep: string
  city: string
  location: unknown
  neighborhood: string
  service: string
  state: (typeof states)[number]
  street: string
}

export interface BrasilAPIBanksResponse {
  ispb: string
  name: string
  code: number | null
  fullName: string
}

interface RedesSociais {
  facebook?: string | null
  instagram?: string | null
  youtube?: string | null
  linkedin?: string | null
  outro?: string | null
}

type PerfilIntegrafor = DadosEmpresaFormValues &
  DadosContatoEmpresaFormValues & {
    arquivoLicenseId: FileRecord[]
    arquivosProjetos: FileRecord[]
    arquivosEnergiaProjetos: FileRecord[]
    volumeProjetosMes: Option<string>
    valorMedioProjetos: Option<string>
    porcentagemProjetosFinanciados: Option<string>
    empresa: {
      dunsNumber?: string
      licenseNumber?: string
      volumeProjetosMes: Option<string>
      valorMedioProjetos: Option<string>
      porcentagemProjetosFinanciados: Option<string>
      arquivoContratoSocial?: FileRecord
      arquivoComprovanteEndereco?: FileRecord
      arquivoComprovanteIdentidade?: FileRecord
    }
  } & { empresa: { experiencia: '0' | '1' } } & {
    empresa: { redesSociais?: RedesSociais }
  } & { integrador: { email: string } }

type PerfilVendedor = {
  nomeCompleto: string
  cpf: string
  contato: {
    email: string
    celular?: string
    whatsapp?: string
    telefone?: string
  }
  endereco: ResidenciaCamposFormValues
}

export type PerfilClientePf = {
  cliente: {
    nome: string
    cpf: string
    email: string
    celular?: string
    whatsapp?: string
    telefone?: string
    endereco: ResidenciaCamposFormValues
    empresa?: Empresa & {
      endereco: ResidenciaCamposFormValues
    }
    renda_mensal?: string
  }
}

export type PerfilClientePj = {
  cliente: Empresa & {
    renda_mensal?: string
    endereco: ResidenciaCamposFormValues
  }
}

export type PerfilClientePjInitial = {
  renda_mensal?: string
  cliente: Empresa & {
    endereco: ResidenciaCamposFormValues
  }
}

export type PerfilIntegradorFormValues = DadosEnderecoFormValues &
  ResponsavelTecnicoFormValues &
  PerfilIntegrafor &
  ContasBancariasFormValues &
  ChavesPixFormValues &
  Partial<ConcessionariasFormSectionFormValue> &
  Partial<DistribuidoresFormSectionFormValues> &
  Partial<EquipamentosFormSectionFormValues>

export type PerfilDistribuidorFormValues = DadosEmpresaFormValues &
  DadosContatoEmpresaFormValues & {
    numeroIntegradoresCadastrados: Option<string>
    volumeProjetosMes: Option<string>
    valorMedioProjetos: Option<string>
    porcentagemProjetosFinanciados: Option<string>
    credencial: {
      email: string
    }
    empresa: RedesSociaisFormValues & {
      arquivoComprovanteEndereco: FileRecord
      arquivoContratoSocial: FileRecord
      placas: Equipamento[]
      inversores: Equipamento[]
    }
  }

export type PerfilVendedorFormValues = DadosEnderecoFormValues & PerfilVendedor

export type PerfilClienteFormValues = DadosEnderecoFormValues &
  Partial<PerfilClientePf> &
  Partial<PerfilClientePj>

export interface TransactionAttempt {
  id: string
  error: string | null
  gateway?: { id: string; name: string; allowBypass: boolean }
  status: string
  value: number
  baseValue: number
  milestoneId: string | null
  milestone?: Milestone
  takeRate: number | null
  gatewayFeeRate: number | null
  beneficiaryName: string
  beneficiaryAccount: string
  beneficiaryPassportUrl: string | null
}
