const exportMap = {
  CLICKSIGN_URL: {
    key: 'NEXT_PUBLIC_CLICKSIGN_URL',
    value: process.env.NEXT_PUBLIC_CLICKSIGN_URL,
  },

  NEUROTECH_IFRAME_BASEURL: {
    key: 'NEXT_PUBLIC_NEUROTECH_IFRAME_BASEURL',
    value: process.env.NEXT_PUBLIC_NEUROTECH_IFRAME_BASEURL,
  },
  NEUROTECH_IFRAME_USER: {
    key: 'NEXT_PUBLIC_NEUROTECH_IFRAME_USER',
    value: process.env.NEXT_PUBLIC_NEUROTECH_IFRAME_USER,
  },
  NEUROTECH_IFRAME_PASS: {
    key: 'NEXT_PUBLIC_NEUROTECH_IFRAME_PASS',
    value: process.env.NEXT_PUBLIC_NEUROTECH_IFRAME_PASS,
  },

  SOCKET_URL: {
    key: 'NEXT_PUBLIC_SOCKET_URL',
    value: process.env.NEXT_PUBLIC_SOCKET_URL,
  },

  FORMALIZACAO_URL: {
    key: 'NEXT_PUBLIC_FORMALIZACAO_URL',
    value: process.env.NEXT_PUBLIC_FORMALIZACAO_URL,
  },

  PORTAL_BACKEND: {
    key: 'NEXT_PUBLIC_PORTAL_BACKEND',
    value: process.env.NEXT_PUBLIC_PORTAL_BACKEND,
  },

  STORAGE_BACKEND: {
    key: 'NEXT_PUBLIC_STORAGE_BACKEND',
    value: process.env.NEXT_PUBLIC_STORAGE_BACKEND,
  },

  SIMULACAO_MS: {
    key: 'NEXT_PUBLIC_SIMULACAO_MS',
    value: process.env.NEXT_PUBLIC_SIMULACAO_MS,
  },

  LANGUAGE: {
    key: 'NEXT_PUBLIC_LANGUAGE',
    value: process.env.NEXT_PUBLIC_LANGUAGE,
  },

  PUBLIC_URL: {
    key: 'NEXT_PUBLIC_URL',
    value: process.env.NEXT_PUBLIC_URL,
  },

  NEXT_PUBLIC_PORTAL_BACKEND: {
    key: 'NEXT_PUBLIC_PORTAL_BACKEND',
    value: process.env.NEXT_PUBLIC_PORTAL_BACKEND,
  },

  NEXT_PUBLIC_WEBSITE: {
    key: 'NEXT_PUBLIC_WEBSITE',
    value: process.env.NEXT_PUBLIC_WEBSITE,
  },

  CLICKSIGN_SECRET: {
    key: 'NEXT_PUBLIC_CLICKSIGN_SECRET',
    value: process.env.NEXT_PUBLIC_CLICKSIGN_SECRET,
  },
} as const

type Env = Record<keyof typeof exportMap, string>

const missing = [] as string[]

Object.values(exportMap).forEach(({ value, key }) => {
  if (value === undefined) missing.push(key)
})

if (missing.length > 0 && process.env.NODE_ENV !== 'test') {
  throw new Error(`Missing environment variables:\n\n${missing.join('\n')}\n`)
}

const env: Env = Object.entries(exportMap).reduce(
  (a, [key, { value }]) => ({
    ...a,
    [key]: value,
  }),
  {} as Env
)

export { env }
