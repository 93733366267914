import { useQuery } from '@tanstack/react-query'

import * as fetch from '@/utils/fetch'
import type { FileRecord } from '@/utils/types/common'

export interface LoanType {
  id: string
  name: string
  label: string
  description: string
  active: boolean
  isSecured: boolean | null
  isUnsecured: boolean | null
  createdAt: string
  updatedAt: string
  icon: FileRecord | null
  regionsRestriction: { id: string; label: string }[]
  regionsRestrictionInverted: boolean | null
  projectTypesRestriction: { id: string; title: string }[]
  projectTypesRestrictionInverted: boolean | null
  condicoes: Record<string, any> | null
  zipCodeRestriction: string[] | null
  zipCodeRestrictionInverted: boolean | null
}

export async function fetchLoanTypes({
  signal,
  params,
}: { signal?: AbortSignal; params?: URLSearchParams } = {}) {
  return await fetch
    .portal<LoanType[]>(`/proposta/loan-type?${params}`, { signal })
    .then(({ data }) => data)
}

export function useLoanTypes() {
  return useQuery({
    queryKey: ['loan-types'],
    queryFn: ({ signal }) => fetchLoanTypes({ signal }),
    refetchOnWindowFocus: false,
    staleTime: 30000,
  })
}
