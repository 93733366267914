import { env } from './envs'

export const PRIVACY_URL =
  env.LANGUAGE === 'pt-br'
    ? 'https://eosfin.com.br/privacidade'
    : 'https://eosloan.com/privacy-policy'
export const TERMS_URL =
  env.LANGUAGE === 'pt-br'
    ? 'https://eosfin.com.br/termos'
    : 'https://eosloan.com/terms'
export const ACCESS_TOKEN_COOKIE = 'session'
export const INVITE_TOKEN_LOCALSTORAGE_KEY = '__invite_token'
export const LOGIN_REDIRECT_ROUTE = '/projects'
// This is used to logout if token has expired
export const UNAUTH_REDIRECT_COOKIE = 'eos.unauth_redirect'

export const BAD_LUCKY_MESSAGE =
  env.LANGUAGE === 'en'
    ? 'We encountered an unexpected error while performing this operation. Please contact our team so we can resolve the issue.'
    : 'Recebemos um erro inesperado ao executar essa operação. Por favor, entre em contato com nossa equipe para solucionarmos o problema.'

export const SCHEMA_PARCELAS_COM_SEGURO = [
  60, 72, 84, 96, 108, 120, 150, 180, 210, 240,
]

export const SCHEMA_PARCELAS_SEM_GARANTIA = [12, 24, 36, 48, 60, 72, 84]
