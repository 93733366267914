import { ACCESS_TOKEN_COOKIE } from '@/utils/constants'

import { env } from './envs'
import type { ServerResponse } from './types'

export const portalEndpoint = env.PORTAL_BACKEND
export const clicksignEndpoint = env.CLICKSIGN_URL
export const storageEndpoint = env.STORAGE_BACKEND
export const simulacaoMsEndpoint = env.SIMULACAO_MS
export const formalizacaoEndpoint = env.FORMALIZACAO_URL

const makeApiFetcher = (prefix: string) => {
  const fetcher = async <T>(
    url: string,
    init?: RequestInit & {
      noparse?: boolean
      req?: any
    }
  ) => {
    if (!url.startsWith('/'))
      throw "Auto prefixed fetch URL must be started with '/'"

    let token = init?.req?.cookies?.[ACCESS_TOKEN_COOKIE]
    if (typeof init?.req?.cookies?.get === 'function') {
      token ||= init?.req?.cookies?.get(ACCESS_TOKEN_COOKIE)?.value
    }

    // for Server Components
    if (typeof window === 'undefined') {
      token ||= await import('next/headers')
        .then(
          async ({ cookies }) =>
            (await cookies()).get(ACCESS_TOKEN_COOKIE)?.value || undefined
        )
        .catch(() => undefined)
    }
    const options: RequestInit = {}

    if (token) {
      options.headers = {
        ...options.headers,
        Cookie: `${ACCESS_TOKEN_COOKIE}=${token}`,
      }
    }

    if (prefix === storageEndpoint) {
      console.log({
        ...options,
        ...init,
        ...([portalEndpoint, formalizacaoEndpoint].includes(prefix) && {
          credentials: 'include',
        }),
        headers: { ...options.headers, ...init?.headers },
      })
    }

    return fetch(`${prefix}${url}`, {
      ...options,
      ...init,
      ...([portalEndpoint, formalizacaoEndpoint].includes(prefix) && {
        credentials: 'include',
      }),
      headers: { ...options.headers, ...init?.headers },
    })
      .then(async (r) => {
        if (init?.noparse) {
          if (r.ok) return r
          else throw r
        }

        const parsed = r.headers
          .get('Content-Type')
          ?.startsWith('application/json')
          ? await r.json()
          : await r.text()

        if (!r.ok) {
          throw parsed
        } else {
          return parsed
        }
      })
      .then((j) => j as ServerResponse<T>)
  }

  return fetcher
}

// export const portal = makeApiFetcher<PortalRoutes>(portalEndpoint)
export const portal = makeApiFetcher(portalEndpoint)
export const clicksign = makeApiFetcher(clicksignEndpoint)
export const storage = makeApiFetcher(storageEndpoint)
export const formalizacao = makeApiFetcher(formalizacaoEndpoint)

// export const $fetch = { portal, storage }
