import { useQuery } from '@tanstack/react-query'

import * as fetch from '@/utils/fetch'

interface ProjectType {
  id: string
  name: 'SOLAR' | 'HOMEIMPROVE'
  title: string
}

export async function fetchProjectTypes({
  signal,
  params,
}: { signal?: AbortSignal; params?: URLSearchParams } = {}) {
  return await fetch
    .portal<ProjectType[]>(`/proposta/project-type?${params}`, { signal })
    .then(({ data }) => data)
}

export function useProjectTypes() {
  return useQuery({
    queryKey: ['project-types'],
    queryFn: ({ signal }) => fetchProjectTypes({ signal }),
  })
}
