import { useInfiniteQuery } from '@tanstack/react-query'

import { makePrismaWhere } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import type { Funding } from '@/utils/types/structs/funding'

interface Params {
  limit?: number
  page?: number
  search?: string
  enabled?: boolean
}

export async function fetchFinanciers({
  signal,
  page = 1,
  params,
}: {
  signal?: AbortSignal
  params?: URLSearchParams
  page?: number
}) {
  return await fetch
    .portal<{ fundings: Funding[]; count: number }>(`/funding?${params}`, {
      signal,
    })
    .then(({ data }) => ({ ...data, page }))
}

export const useFinanciadores = ({
  limit = 50,
  page = 1,
  search = '',
  enabled = true,
}: Params = {}) => {
  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: ['fundings', { limit, search }] as const,
    queryFn: async ({ signal, pageParam = page }) => {
      const params = new URLSearchParams()
      params.set('limit', String(limit))
      params.set('page', String(pageParam))
      params.set('sort', 'razaoSocial')
      params.set('order', 'asc')

      if (search) {
        params.set(
          'filter',
          JSON.stringify(
            makePrismaWhere(search, {
              OR: ['razaoSocial'],
            })
          )
        )
      }

      return await fetchFinanciers({
        signal,
        params,
        page: pageParam,
      })
    },
    getNextPageParam: ({ page }) => page + 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  })

  return {
    data: data?.pages?.flatMap(({ fundings }) => fundings) || [],
    fetchNextPage,
  }
}
